$background-color: #794CFF;
$circle-size: 50;
$width: 600;

.newTicket {
    min-height: 100vh;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #794CFF;
    margin: auto;
    padding-bottom: 20px;
}
.holes-top {
	height: $circle-size + px;
	width: $circle-size + px;
	background-color: $background-color;
	border-radius: 50%;
	position: absolute;
	left:50%;
	margin-left: ($circle-size / -2) + px;
	top: ($circle-size / -2) + px;
	
	&:before,
	&:after {
		content: '';
		height: $circle-size + px;
		width: $circle-size + px;
		background-color: $background-color;
		position: absolute;
		border-radius: 50%;
	}
	&:before {
		left: ($width / -2) + px;
	}
	&:after {
		left: ($width / 2) + px;
	}
}
.holes-lower {
	position: relative;
	margin:25px;
	border:1px dashed #aaa;
	&:before,
	&:after {
		content: '';
		height: $circle-size + px;
		width: $circle-size + px;
		background-color: $background-color;
		position: absolute;
		border-radius: 50%;
	}
	
	&:before {
		top: -25px;
		left: ($circle-size / -1) + px;
	}
	&:after {
		top: -25px;
		left: ($width - $circle-size) + px;
		// left: 550 + px;
	}
}
.ticketContainer {
    height: 100%;
    width: $width+px;
    min-width: 400px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: white;
    margin: 25px auto;
    position: relative;
    gap: 12px;
}
.ticketItem {
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 80px;
    margin-bottom: 15px;
}
.ticketItem:last-child {
    padding: 20px 25px 20px 25px;
}
.ticketSectionTitle {
    padding: 0px 50px 0px;
    color: #5009B5;
}
.ticketSectionTitle:first-of-type {
    padding: 50px 0 0 50px;
    color: #5009B5;
}
.ticketText {
    font-size: 20px;
    padding: 0px 50px 0px;
}
.ticketInput {
    height: 1.5rem;
    margin: 0 50px 0px;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    height: 2rem;
    font-size: 1.3rem;
    border: 1px solid rgb(176, 180, 180);
}
textarea {
    margin: 0px 50px 0px;
    padding: 5px;
    font-size: 1.3rem;
    resize: none;
}

.ticketSelect {
    margin: 0px 50px 0px;
    border: 1px solid rgb(176, 180, 180);
    border-radius: 5px;
    outline: none;
    color: #231E33;
    width: max-content;
    height: 3.5rem;
    line-height: 1rem;
    font-size: 1.3rem;
    cursor: pointer;
    padding-right: 10px;
    padding-left: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
}
.submitTicketButton {
    margin: 0px 25px 0px;
    border: solid 10px #01BBBA;
    padding: 15px 25px;
    margin-bottom: 25px;
    background-color: white;
    /* background-color: #794CFF; */
    /* background-color: #44B8F3; */
    /* background-color: #01BBBA; */
    /* background-color: #5009B5; */
    color: #01BBBA;
    font-weight: bolder;
    font-size: 1.5rem;
    cursor: pointer;
    border-radius: 15px;
    text-transform: capitalize;
}
.submitTicketButton:disabled {
    color: gray;
    border: solid 10px gray;
    cursor: not-allowed;
}