$background-color: white;
$circle-size: 50;
$width: 500;

.actionModal {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.actionModalContainer {
    width: 600px;
    height: max-content;
    background-color: white;
    padding: 20px;
    padding-top: 30px;
    position: relative;
    border-radius: 10px;
}
.actionModalWrap {
    width: 500px;
    height: max-content;
    background-color: white;
    position: relative;
    margin: 0 auto;
}
.actionCloseModal {
    position: absolute;
    top: -7px;
    right: -7px;
    cursor: pointer;
    color: black;
    font-size: 30px;
}
.actionModalTitle {
    display: flex;
    align-content: center;
    justify-content: center;
    text-transform: capitalize;
    height: 40px;
    text-align: center;
    font-size: 30px;
    padding: 50px 25px 0px;
    color: #5009B5;
    font-weight: bolder;
}
.actionModalTitle h3 {
    padding: 0;
}
.actionModalForm {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
}
.actionModalTicketWrap {
    display: flex;
    width: 500px;
    flex-direction: column;
    background-color: rgb(215, 215, 226);
}
.holes-top-ticket {
	height: $circle-size + px;
	width: $circle-size + px;
	background-color: $background-color;
	border-radius: 50%;
	position: absolute;
	left:50%;
	margin-left: ($circle-size / -2) + px;
	top: ($circle-size / -2) + px;
	
	&:before,
	&:after {
		content: '';
		height: $circle-size + px;
		width: $circle-size + px;
		background-color: $background-color;
		position: absolute;
		border-radius: 50%;
	}
	&:before {
		left: ($width / -2) + px;
	}
	&:after {
		left: ($width / 2) + px;
	}
}
.holes-lower-ticket {
    height: $circle-size + px;
	width: $circle-size + px;
	background-color: $background-color;
	border-radius: 50%;
	position: absolute;
	left:50%;
	margin-left: ($circle-size / -2) + px;
	bottom: ($circle-size / 0.77) + px;
	
	&:before,
	&:after {
		content: '';
		height: $circle-size + px;
		width: $circle-size + px;
		background-color: $background-color;
		position: absolute;
		border-radius: 50%;
	}
	&:before {
		left: ($width / -2) + px;
	}
	&:after {
		left: ($width / 2) + px;
	}
}
.actionModalFormItem {
    font-size: 24px;
    display: flex;
    flex-direction: column;
    text-align: center;
}
.actionModalFormItem>h3 {
    padding: 0px 25px 0px;
    font-size: 48px;
    margin-top: 1px;
}
.actionModalInput {
    width: 250px;
    padding: 10px;
}
.actionModalBtn {
    border: solid 10px #01BBBA;
    padding: 10px 20px;
    background-color: white;
    color: #01BBBA;
    font-size: larger;
    font-weight: bold;
    cursor: pointer;
    border-radius: 10px;
    margin-top: 30px;
}
.actionModalBtn:disabled {
    background-color: #888BF1;
    cursor: not-allowed;
}