.ticketListContainer {
    height: max-content;
    min-height: calc(100vh - 9vh);
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
}
.ticketListHeader {
    width: 100%;
    height: 64px;
    background-color: rgb(141, 35, 212);
    color: white;
}
.ticketListHeader>span {
    padding-left: 100px;
    line-height: 64px;
}
.ticketListContent {
    display: flex;
    justify-content: center;
    padding: 10px;
}
.ticketListContentWrapper {
    width: 100%;
    display: flex;
    gap: 20px;
    flex-direction: column;
}
.ticketListContentList {
    font-size: 16px;
}
@media only screen and (max-width: 600px) {
    .ticketListContent {
        padding-left: 0px;
        padding-right: 0px;
    }
    .ticketListHeader>span {
        padding-left: 30px;
    }
}