.ticketCardItem {
    padding: 0px 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
}
.ticketCardItem:first-child {
    margin-top: 10px;
}
.ticketCardItemType {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 12px;
}
.ticketCardItemTypeImg {
    height: 15px;
    padding-left: 10px;
}
.ticketCardItem>h4, .ticketCardItem>span {
    padding-left: 10px;
}
.ticketCardItem>span {
    font-size: 12px;
}
.ticketCardItemBox {
    border: 1px solid lightgray;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 10px;
}
.ticketCardItemBox:hover {
    border: 1px solid black;
}
.ticketCardItemBoxImg {
    width: 150px;
    height: 150px;
    object-fit: cover;
    flex: 1;
}
.ticketCardItemBoxDesc {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex: 4;
    justify-content: space-evenly;
}
.ticketCardItemBoxDesc>label {
    padding: 0px 25px 0px;
}
.itemBoxWrap {
    display: flex;
    width: 300px;
    flex-direction: column;
    flex: 2;
    justify-content: flex-start;
}
.itemBoxWrap:first-child {
    flex: 1;
}
.ticketCardItemBoxName {
    font-size: 20px;
    color: #6726C3;
}
.ticketCardItemSubtitle {
    font-size: 15px;
}
.ticketCardItemDetails {
    flex: 1;
    display: flex;
    height: 100px;
    flex-direction: column;
    align-self: center;
    justify-content: space-between
}
.ticketCardItemDetailsBtn {
    background-color: rgb(100, 19, 153);
    color: white;
    font-weight: bold;
    padding: 10px 5px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}
@media only screen and (max-width: 600px) {
    .ticketCardItem {
        padding-left: 5px;
        padding-right: 5px;
    }
    .ticketCardItemBox {
        gap: 10px;
    }
    .ticketCardItemBoxImg {
        width: 100px;
        height: 170px;
        object-fit: cover;
    }
    .ticketCardItemDates {
        font-size: 14px;
        width: 130px;
    }
    .ticketCardItemBoxName {
        font-size: 16px;
    }
    .ticketCardItemSubtitle {
        font-size: 12px;
        font-weight: bold;
    }
}