.login {
    height: 100vh;
    width: 50vw;
    display: flex;
    align-items: center;
    margin: auto;
    justify-content: center;
    flex-direction: column;
    background-color: white;
}
.upperSection, .lowerSection {
    display: flex;
    flex-direction: column;
}
.loginCompanyLogo {
    width: 300px;
}
.loginTitles {
    display: flex;
    width: max-content;
    align-items: center;
    flex-direction: column;
    color: #6726C3;
}
.appName {
    font-weight: 700;
    font-size: 18px;
}
.companyName {
    font-size: 12px;
}

.loginContainer {
    display: flex;
    margin-top: 30px;
    flex-direction: column;
    width: 300px;
    gap: 10px;
}
.locationList {
    width: 300px;
    height: 50px;
    margin: 0;
    padding: 10px;
}

.loginInput {
    height: 30px;
    padding: 10px;
    border: none;
}
.loginInput.domainId {
    text-transform: uppercase;
    margin: 0;
}
.loginInput.password {
    width: 300px;
    flex: 1;
    padding-right: 0px;
    margin: 0;
}
.loginButton {
    border: solid 5px #01BBBA;
    padding: 10px 20px;
    background-color: white;
    color: #01BBBA;
    font-size: larger;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
}

.loginButton:disabled {
    background-color: #888BF1;
    cursor: not-allowed;
}
.loginError {
    color: red;
    text-align: center;
}
.loginPasswordWrap {
    display: flex;
    flex-direction: row;
    position: relative;
}
.loginEye {
    position: absolute;
    cursor: pointer;
    align-self: center;
    flex: 1;
    margin-left: 90%;
}
@media only screen and (max-width: 916px) {
    .login {
        width: 90vw;
    }
    .lowerSection {
        display: none;
    }
}