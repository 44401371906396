.searchContainer {
    height: max-content;
    min-height: calc(100vh - 9vh);
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
}
.searchHeader {
    width: 100%;
    height: 64px;
    background-color: rgb(141, 35, 212);
    color: white;
}
.searchHeader>span {
    padding-left: 100px;
    line-height: 64px;
}
.searchContent {
    display: flex;
    height: 75vh;
    justify-content: center;
    padding: 10px;
}
.searchContentWrapper {
    width: 100%;
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.searchContentList {
    font-size: 16px;
}