.feedbackContentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.surveyContentWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}
.surveyVerified {
    display: flex;
    gap: 5px;
    align-items: center;
    padding-left: 5px;
}
.surveyVerifiedCheck {
    color: green;
}
.surveyErrorMsg {
    font-size: 12px;
    margin-top: 10px;
}
.surveyQuestionsWrapper {
    display: flex;
    flex-direction: column;
}
.surveyItemWrapper {
    display: flex;
    padding: 5px;
    gap: 5px;
}
.surveyItemWrapper.first {
    border-bottom: 1px solid black;
}
.surveyItem {
    flex: 1;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
}
.surveyEmoticon {
    font-size: 24px;
}
.surveyItem>label {
    display: none;
}
.surveyCategory {
    flex: 4;
    font-size: 1.5em;
    padding-right: 20px;
}
.surveyRadio {
    height: 30px;
    width: 30px;
}
.siteSelect {
    border: 1px solid rgb(176, 180, 180);
    border-radius: 5px;
    outline: none;
    color: #231E33;
    width: max-content;
    height: 3.5rem;
    line-height: 1rem;
    font-size: 1.3rem;
    cursor: pointer;
    padding-right: 10px;
    padding-left: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
}
#feedbackAdditionalComments {
    margin: 0;
    width: 90vw;
}
@media only screen and (max-width: 2000px) {
    
    .surveyQuestionsWrapper {
        height: 400px;
        overflow-x: hidden;
    }
    .surveyItemWrapper.first {
        display: none;
    }
    .surveyItemWrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .surveyItemWrapper:first-child {
        display: none;
    }
    .surveyItem {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }
    .surveyItem>label {
        display: flex;
        align-items: center;
        gap: 3px;
        font-size: 1.1em;
        line-height: 1.1em;
    }
    .surveyItem.actionButtons {
        display: flex;
        justify-content: space-between;
        margin-right: 10px;
    }
    .feedbackCancelBtn {
        margin: 0;
        border: solid 10px #01BBBA;
        padding: 10px 20px;
        background-color: white;
        color: #01BBBA;
        font-weight: bolder;
        font-size: 1rem;
        cursor: pointer;
        border-radius: 15px;
        text-transform: capitalize;
        margin-bottom: 10px;
    }
    .feedbackSubmitBtn {
        margin: 0;
        border: solid 10px #01BBBA;
        padding: 10px 20px;
        background-color: white;
        color: #01BBBA;
        font-weight: bolder;
        font-size: 1rem;
        cursor: pointer;
        border-radius: 15px;
        text-transform: capitalize;
        margin-bottom: 10px;
    }
    .feedbackSubmitBtn:disabled {
        color: gray;
        border: solid 10px gray;
        cursor: not-allowed;
    }
}