
.ticketInfoContainer {
    height: max-content;
    min-height: calc(100vh - 9vh);
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
}
.ticketInfoHeader {
    width: 100%;
    height: 64px;
    background-color: rgb(141, 35, 212);
    color: white;
}
.ticketInfoHeader>span {
    padding-left: 100px;
    line-height: 64px;
}
.ticketInfoWrapper {
    display: flex;
    gap: 20px;
    padding-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    width: 90%;
    align-self: center;
}
.ticketInfoActionBox {
    flex: 1;
    background-color:#E1EDFF;
    border-radius: 10px;
    top: 10px;
    height: max-content;
    padding: 10px 10px;
    flex-direction: column;
}
.ticketInfoTitle {
    font-size: 20px;
    color: #5009B5;
    margin-bottom: 10px;
}
.ticketInfoActionBoxItem {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 10px;
}
.ticketInfoActionBoxItem>label {
    font-size: 12px;
    font-weight: 600;
}
.statusWrapper {
    display: flex;
    align-content: center;
    align-items: center;
    background-color: white;
    justify-content: center;
    color: black
}
.statusWrapper.green {
    color: green;
}
.statusWrapper.red {
    color: red;
}
.ticketInfoActionBoxItemStatus {
    height: 30px;
    padding: 5px;
    background-color: white;
    display: flex;
    align-items: center;
    font-weight: bold;
}
.ticketInfoActionBoxItemStatus.green {
    color: green;
}
.ticketInfoActionBoxItemStatus.red {
    color: red;
}
.reservationRemarks {
    display: flex;
    height: 30px;
    padding: 5px;
    background-color: white;
    align-items: center;
    justify-content: center;
}
.ticketInfoActionBox>button {
    padding: 10px;
    color: white;
    border: none;
    font-weight: 500;
    width: 100%;
    cursor: pointer;
    margin-bottom: 10px;
    border-radius: 5px;
}
.ticketInfoApproveBtn {
    background-color: green;
}
.ticketInfoRejectBtn {
    background-color: red;
}
.ticketInfoDefaultBtn {
    background-color: rgb(100, 19, 153);
}
.ticketInfoDefaultBtn:disabled, .ticketInfoRejectBtn:disabled {
    background-color: #b2aeb8;
    cursor: not-allowed;
}
.ticketInfoRightPanel {
    flex: 3;
}
.ticketInfoPanelContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #F5F5F5;
    border-radius: 10px;
    color: #231E33;
}
.ticketInfoItemsWrapper {
    display: flex;
    align-content: center;
    justify-content: center;
    gap: 10px;
    padding: 20px;
}
.ticketInfoItemsColumn {
    display: flex;
    align-content: center;
    justify-content: space-evenly;
    flex-direction: column;
    gap: 10px;
    width: 180px;
}
.ticketInfoItem {
    font-size: 14px;
    display: flex;
    flex-direction: column;
}
.ticketInfoItem>label {
    font-weight: 600;
    font-size: 14px;
}
.ticketInfoItem>span {
    font-size: 13px;
}
@media only screen and (max-width: 600px) {
    .ticketInfoHeader>h3 {
        padding-left: 30px;
    }
    .ticketInfoWrapper {
        padding-top: 10px;
        padding-left: 5px;
        flex-direction: column;
    }
    .ticketInfoTitle {
        font-size: 16px;
    }
    .ticketInfoActionBoxItemWrapper {
        display: flex;
        flex-direction: row;
        gap: 5px;
    }
    .ticketInfoActionBoxItem {
        width: 50%;
    }
    .ticketInfoActionBoxItemStatus {
        font-size: 12px;
    }
    .reservationRemarks {
        font-size: 12px;
    }
    .ticketInfoRightPanel, .ticketInfoPanelContainer {
        display: flex;
        height: 100%;
        width: 100%;
        overflow-x: hidden;
    }
    .ticketInfoItemsWrapper {
        display: flex;
        flex-direction: column;
    }
}