.bcsuContainer {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
}
.bcWrapper {
    display: flex;
}
.bcsuTitle {
    font-weight: 500;
    color: gray;
    font-size: 20px;
}