.feedbackModal {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.feedbackModalContainer {
    width: 320px;
    height: max-content;
    background-color: white;
    padding: 20px;
    padding-top: 30px;
    position: relative;
    border-radius: 10px;
}
.feedbackModalWrap {
    width: 100%;
    height: max-content;
    background-color: white;
    position: relative;
    margin: 0 auto;
}
.feedbackModalForm {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
}
.feedbackModalTicketWrap {
    display: flex;
    width: 100%;
    flex-direction: column;
    background-color: rgb(215, 215, 226);
}
.feedbackModalFormItem {
    font-size: 24px;
    display: flex;
    flex-direction: column;
    text-align: center;
}
.feedbackModalTitle {
    display: flex;
    align-content: center;
    justify-content: center;
    text-transform: capitalize;
    height: 30px;
    text-align: center;
    font-size: 20px;
    padding: 10px 5px 0px;
    color: #5009B5;
    font-weight: bolder;
}
.feedbackModalFormItem>p {
    font-size: 16px;
}
.feedbackModalBtn {
    border: solid 10px #01BBBA;
    padding: 10px 20px;
    background-color: white;
    color: #01BBBA;
    font-size: larger;
    font-weight: bold;
    cursor: pointer;
    border-radius: 10px;
    margin-top: 10px;
}