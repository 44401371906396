.landing {
    height: 100vh;
    width: 95vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: white;
    margin: auto;
    gap: 80px;
}

.landingUpperSection {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
}

.landingLowerSection {
    display: flex;
    flex-direction: column;
}

.landingCarehubLogo {
    width: 500px;
}
.landingLocationIcon {
    margin-bottom: 3px;
    margin-left: 4px;
    font-size: 20px;
}
.landingText {
    font-size: 24px;
    margin-left: 12px;
}

.landingButton {
    border: solid 10px #01BBBA;
    padding: 15px 25px;
    background-color: white;
    /* background-color: #794CFF; */
    /* background-color: #44B8F3; */
    /* background-color: #01BBBA; */
    /* background-color: #5009B5; */
    color: #01BBBA;
    font-weight: bolder;
    font-size: 1.5rem;
    cursor: pointer;
    border-radius: 15px;
    text-transform: capitalize;
}
@media only screen and (max-width: 916px) {
    .landingLowerSection, .locationWrap {
        display: none;
    }
    .landingCarehubLogo {
        width: 320px;
    }
}
@media only screen and (max-width: 300px) {
    .landingCarehubLogo {
        width: 250px;
    }
}