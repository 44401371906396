.reportsContainer {
    display: flex;
    flex-direction: column;
    height: max-content;
    width: 100vw;
    background-color: white;
}
.reportsContainer>h2 {
    padding: 10px 0px 0px 20px;
    margin: 0;
}
.reportSectionWrapper {
    width: 100vw;
    display: grid;
    padding-top: 20px;
}
.reportsSection {
    padding: 5px 10px 0px 20px;
    margin-left: 50px;
}
.reportName {
    width: 400px;
    text-align: left;
}
.item1 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
}
.item2 {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-start: 2;
}
.item3 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
}
.item {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
}
.reportContent {
    display: flex;
    width: 400px;
    /* margin: auto; */
    border-top: 1px solid #784BFE;
}