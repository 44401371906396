.feedbackPage {
    min-height: 100vh;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #794CFF;
    margin: auto;
}
.feedbackContainer {
    height: max-content;
    width: 700px;
    display: flex;
    justify-content: center;    
    flex-direction: column;
    background-color: white;
    padding: 20px;
}
.inputTicketNumWrapper {
    display: flex;
}
.feedbackUpperSection {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.feedbackTitle {
    display: flex;
    font-size: 2em;
    font-weight: 700;
    margin-bottom: 30px;
    color: #5009B5;
    align-items: center;
    text-align: center;
    line-height: 1.2em;
}
.feedbackTicketNumber {
    display: flex;
    flex-direction: column;
}
.feedbackLowerSection {
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 2000px) {
    .feedbackPage {
        height: 100vh;
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #794CFF;
        margin: 0 auto;
    }
    .feedbackContainer {
        height: 600px;
        width: 100vh;
        max-width: 400px;
        display: flex;
        justify-content: center;    
        flex-direction: column;
        background-color: white;
        padding: 0 10px;
    }
    .feedbackTitle {
        margin-bottom: 30px;
    }
    .inputTicketNumWrapper {
        margin-top: 5px;
        flex-direction: column;
        gap: 5px;
    }
    .feedbackInput {
        margin: 0;
        padding-right: 10px;
        padding-left: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        height: 2rem;
        font-size: 1.3rem;
        border: 1px solid rgb(176, 180, 180);
        text-transform: uppercase;
    }
    .feedbackVerifyBtn {
        margin: 0;
        border: solid 10px #01BBBA;
        padding: 10px 20px;
        background-color: white;
        color: #01BBBA;
        font-weight: bolder;
        font-size: 1rem;
        cursor: pointer;
        border-radius: 15px;
        text-transform: capitalize;
        margin-bottom: 10px;
    }
    .feedbackVerifyBtn:disabled {
        color: gray;
        border: solid 10px gray;
        cursor: not-allowed;
    }
}